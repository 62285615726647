import Application from 'components/comachicart/Application';
import Contact from 'components/comachicart/Contact';
import Func from 'components/comachicart/Func';
import Layout from 'components/comachicart/Layout';
import SEO from 'components/seo';
import React from 'react';

const FuncPage = () => {
  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/func' />
      <Func />
      <Contact />
      <Application />
    </Layout>
  );
};

export default FuncPage;
